// Filename - Header.js

import React, { useEffect, useState } from "react";
import logo from "../assets/logo-cercle-portfolio-removebg.png";
import flagFR from "../assets/flag-france.png";
import flagEN from "../assets/flag-royaume-uni.png";

import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

export default function Header({ page }) {
  const { t, i18n } = useTranslation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const activeClassIf = (condition) => {
    if (!condition) {
      return null;
    }
    if (condition) {
      return "active bg-pedro";
    }
  };

  return (
    <>
      <Navbar expand="lg" fixed="top" className="Nav">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} alt="logo" />
            <span className="HeaderTitle">Lucas Yvernaux</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" id="toggler" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              variant={isSmallScreen ? "" : "underline"}
              className={
                isSmallScreen
                  ? ""
                  : "me-3 my-lg-0 w-100 justify-content-end gap-5"
              }
              navbarScroll
            >
              <Nav.Link href="/" className={activeClassIf(page === "home")}>
                {t("nav.home")}
              </Nav.Link>
              {isSmallScreen ? (
                <Nav.Link
                  href="/Projects"
                  className={activeClassIf(page === "projects")}
                >
                  {t("nav.projects")}
                </Nav.Link>
              ) : (
                <NavDropdown
                  id="navbarScrollingDropdown"
                  title={t("nav.projects")}
                  className={activeClassIf(page === "projects") + " rounded"}
                >
                  <NavDropdown.Item href="/Projects">
                    {t("nav.home")}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/Projects#Equilys">
                    Equilys
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Projects#Kalmia">
                    Kalmia
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Projects#Catalogue">
                    Cataloque
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Projects#Lak">LAK</NavDropdown.Item>
                  <NavDropdown.Item href="/Projects#MangaCollec">
                    Manga Collec
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/Projects#Same">
                    Same
                  </NavDropdown.Item>
                </NavDropdown>
              )}

              <Nav.Link
                className={activeClassIf(page === "about")}
                href="/About"
              >
                {t("nav.about")}
              </Nav.Link>
              <Nav.Link
                className={activeClassIf(page === "contact")}
                href="/Contact"
              >
                {t("nav.contact")}
              </Nav.Link>
            </Nav>
            <Nav.Link
              id="flagFr"
              onClick={() => {
                i18n.changeLanguage("fr");
              }}
              className={i18n.language === "fr" && "d-none"}
            >
              <img src={flagFR} width={30} alt="icon french flag" />
            </Nav.Link>

            <Nav.Link
              onClick={() => {
                i18n.changeLanguage("en");
              }}
              id="flagEng"
              className={i18n.language === "en" && "d-none"}
            >
              <img src={flagEN} width={30} alt="icon U.K flag" />
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
