/*import style */
import "./App.css";
import "./style/style.css";
import "./style/Navbar.css";

// Import Components and Pages
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Projects } from "./pages/Projects";
import { Contact } from "./pages/Contact";
import { NotFound } from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";

// Importy function
import { useHashNavigation } from "./hooks/useHashNavigation";

/* dark Mode with Medium site method*/
import { ThemeContext, ThemeDispatchContext, themeReducer } from "./context";
import { useReducer } from "react";

function App() {
  /** dark Mode with Medium site method */
  const { page, param } = useHashNavigation();
  const pageContent = getPageContent(page, param);
  const [themeRed, dispatch] = useReducer(themeReducer, false);

  return (
    <ThemeContext.Provider value={themeRed}>
      <ThemeDispatchContext.Provider value={dispatch}>
        <div className={themeRed ? "theme__dark" : "App"}>
          <Header page={page} />
          <div className="Content">
            <div id={page}>{pageContent}</div>
          </div>
          <Footer />
        </div>
      </ThemeDispatchContext.Provider>
    </ThemeContext.Provider>
  );
}

function getPageContent(page, param) {
  if (page === "home") {
    return <Home />;
  }
  if (page === "about") {
    return <About />;
  }
  if (page === "projects") {
    return <Projects />;
  }
  if (page === "contact") {
    return <Contact />;
  }

  return <NotFound page={page} />;
}

export default App;
