import { useRef, useState } from "react";
import { Input, Button } from ".";
import { Modal, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export function ContactForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    company: "",
    role: "",
    email: "",
    subject: "",
    bodyMessage: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFocusOut = (e) => {
    validateForm();
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate lastname
    if (!formData.lastName) {
      newErrors.lastName =
        t("contact.form.labels.lastName") +
        " " +
        t("contact.form.errors.empty");
      isValid = false;
    }
    // Validate firstname
    if (!formData.firstName) {
      newErrors.firstName =
        t("contact.form.labels.firstName") +
        " " +
        t("contact.form.errors.empty");
      isValid = false;
    }
    // Validate email
    if (!formData.email) {
      newErrors.email =
        t("contact.form.labels.mail") + " " + t("contact.form.errors.empty");
      isValid = false;
    } else if (!formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      newErrors.email =
        t("contact.form.labels.mail") + " " + t("contact.form.errors.invalid");
      isValid = false;
    }
    // Validate Subject
    if (!formData.subject) {
      newErrors.subject =
        t("contact.form.labels.subject") + " " + t("contact.form.errors.empty");
      isValid = false;
    }
    // Validate Body
    if (!formData.bodyMessage) {
      newErrors.bodyMessage =
        t("contact.form.labels.message") + " " + t("contact.form.errors.empty");
      isValid = false;
    }
    if (formData.bodyMessage.length < 10 && formData.bodyMessage.length > 0) {
      newErrors.bodyMessage =
        t("contact.form.labels.message") +
        " " +
        t("contact.form.errors.length");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y15rv6s", //serviceID
        "template_k5nglaw", //TemplateID
        formData,
        "tdeeaKm62sv5davM8" //Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, you can submit or process the data here
      if (formData.company === "") {
        formData.company = "Unknown";
      }
      if (formData.role === "") {
        formData.role = "Unknown";
      }
      console.log("Form data:", formData);
      sendEmail(e);
      setShow(true);
    } else {
      setShow(false);
    }
  };

  //const isFormValid = Object.keys(errors).length === 0;

  return (
    <form
      ref={form}
      id="contact"
      className="d-flex flex-column gap-4"
      onSubmit={handleSubmit}
    >
      <Modal show={show} onHide={handleClose} animation={true} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Email Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>Thanks for your message</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="d-inline-flex">
        <Input
          label={t("contact.form.labels.lastName")}
          name="lastName"
          smallInput
          onChange={handleInputChange}
          onBlur={errors.lastName ? handleFocusOut : null}
          InputError={errors.lastName}
        />
        <Input
          label={t("contact.form.labels.firstName")}
          name="firstName"
          smallInput
          value={formData.firstName}
          onChange={handleInputChange}
          onBlur={errors.firstName ? handleFocusOut : null}
          InputError={errors.firstName}
        />
      </Row>
      <Input
        label={
          t("contact.form.labels.company") +
          " (" +
          t("contact.form.optional") +
          ")"
        }
        name="company"
        value={formData.company}
        onChange={handleInputChange}
        onBlur={errors.company ? handleFocusOut : null}
        InputError={errors.company}
      />
      <Input
        label={
          t("contact.form.labels.job") + " (" + t("contact.form.optional") + ")"
        }
        name="role"
        value={formData.role}
        onChange={handleInputChange}
        onBlur={errors.role ? handleFocusOut : null}
        InputError={errors.role}
      />
      <Input
        label={t("contact.form.labels.mail")}
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        onBlur={errors.email ? handleFocusOut : null}
        InputError={errors.email}
      />
      <Input
        label={t("contact.form.labels.subject")}
        value={formData.Subject}
        name="subject"
        onChange={handleInputChange}
        onBlur={errors.subject ? handleFocusOut : null}
        InputError={errors.subject}
      />
      <Input
        label={t("contact.form.labels.message")}
        type="textarea"
        value={formData.bodyMessage}
        name="bodyMessage"
        onChange={handleInputChange}
        onBlur={errors.bodyMessage ? handleFocusOut : null}
        InputError={errors.bodyMessage}
      />
      <div>
        <Button onClick={handleSubmit}>Envoyer</Button>
      </div>
    </form>
  );
}
