import { Carousel } from "react-bootstrap";
import imageEquilys from "../assets/ScreenEquilys-v2.png";
import imageLac from "../assets/ScreenLAC-v2.png";
import imageCatalogue from "../assets/ScreenCatalogue.png";
import { Image } from "react-bootstrap";

export const CarouselCustom = ({ ...props }) => {
  const style = {
    text: {
      backgroundColor: "#6c69fc",
      width: "13rem",
      color: "white",
    },
    image: {
      //maxHeight: "920px",
      //width: "75vw",
    },
  };
  return (
    <div {...props}>
      <Carousel fade variant="dark" className="border rounded border-2">
        <Carousel.Item>
          <Image src={imageEquilys} fluid />
          <Carousel.Caption
            style={style.text}
            className="rounded-pill m-auto px-3 py-2 "
          >
            <h3>Equilys</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image src={imageLac} fluid style={style.image} />
          <Carousel.Caption
            style={style.text}
            className="rounded-pill m-auto px-3 py-2"
          >
            <h3>LAK</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image src={imageCatalogue} fluid style={style.image} />
          <Carousel.Caption
            style={style.text}
            className="rounded-pill m-auto px-3 py-2"
          >
            <h3>Catalogue</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
