import { useState } from "react";

/**
 * @param {"primary" | "secondary" | "sucess" | "danger" |  "warning" | "info" | "light" | "dark" |  "link"} variant
 * @returns {JSX.Element}
 */
export function Button({ variant = "primary", ...props }) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  /** Override style button */
  let myStyle;
  switch (variant) {
    case "primary":
      myStyle = {
        color: isHover ? "#6c69fc" : "white",
        padding: "10px 32px",
        fontSize: 16,
        fontWeight: 500,
        background: isHover ? "white" : "#6c69fc",
      };
      break;
    case "secondary":
      myStyle = {
        padding: "10px 30px",
        color: "#6c69fc",
        border: "2px solid #6c69fc",
        background: "white",
      };
      break;
    case "danger":
      myStyle = {
        borderRadius: 5,
        padding: "10px 30px",
      };
      break;
    default:
      myStyle = {
        padding: "10px 30px",
      };
      break;
  }

  const newProps = {
    ...props,
    style: myStyle,
    className: `btn btn-${variant}`,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  if (props.href) {
    return <a {...newProps} />;
  } else {
    return <button {...newProps} />;
  }
}
