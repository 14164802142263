import { useTranslation } from "react-i18next";
import { ContactForm } from "../components/ContactForm";

export function Contact() {
  const { t } = useTranslation();
  return (
    <div className="my-5">
      <h1
        className="mb-4"
        style={{
          color: "#0F0F0F",
          fontSize: 39.06,
          fontFamily: "Poppins-SemiBold",
          fontWeight: "900",
          wordWrap: "break-word",
        }}
      >
        {t("contact.title")}
      </h1>

      <div className="w-75 m-auto">
        <div className="d-flex flex-column gap-3">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}
