import { useEffect, useState } from "react";

//Import Components
import { Button, Card, ListGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CardPlaceholder from "../components/CardPlaceholder";

//Import image Logo
import logoReact from "../assets/logo.svg";
import logoEmailJS from "../assets/logo-EmailJS.png";
import logoEquilys from "../assets/EQUILYS-AI-RVB-DEGRADE-MINI.svg";
import logoVanaPrincipia from "../assets/Logo-Vanaprincipia.png";
import logoLaravel from "../assets/logoLaravel.min.svg";
import { useTranslation } from "react-i18next";

export function Projects() {
  const { t } = useTranslation();

  //check size screen to update display
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  //Implement Spinner
  const [loading, setLoading] = useState(true);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const styleClasse = {
    section: {
      name: isSmallScreen
        ? "d-flex flex-column gap-3 px-3"
        : "d-flex flex-column gap-5 px-5",
      card: {
        name: isSmallScreen ? "" : "shadow-lg flex-row",
      },
      iframe: {
        name: isSmallScreen ? "" : "m-1 w-50",
      },
      content: {
        name: isSmallScreen ? "" : "w-50",
        title: {},
        text: {},
      },
    },
  };
  const handleClickDisabled = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <section className="my-4">
        <h1 className="display-1 mb-5">{t("projects.title")}</h1>
        <section className={styleClasse.section.name}>
          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name} id="Equilys">
              <iframe
                className={styleClasse.section.iframe.name}
                src="https://equilys.yvernaux.ynh.fr/"
                title="site Equilys"
                height={500}
                style={{ display: loading ? "none" : "block" }}
              ></iframe>
              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>{t("projects.equilys.title")}</Card.Title>
                  <Card.Text>{t("projects.equilys.text")}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    {t("projects.partner")} :
                    <img
                      src={logoReact}
                      className="img-fluid me-2"
                      alt="logo de React"
                      width={25}
                    />
                    <Icon.BootstrapFill size={20} color="purple" />
                    <img
                      src={logoEquilys}
                      className="img-fluid mx-2"
                      alt="logo de React"
                      width={25}
                    />
                    <Icon.Gitlab size={20} color="orange" />
                    <img
                      src={logoEmailJS}
                      className="img-fluid ms-2"
                      alt="logo d'EmailJS"
                      width={25}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("projects.member")} : Chloé Daudier - Nicolas Cothereau -
                    Lucas YVERNAUX
                  </ListGroup.Item>
                  <ListGroup.Item>2024</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://equilys.yvernaux.ynh.fr/"
                    aria-label="Link to website"
                    target="_blank"
                  >
                    <Button variant="primary">
                      {t("projects.CTAWebsite")}
                    </Button>
                  </Card.Link>
                  <Card.Link
                    href="https://gitlab.com/lucas.yvernaux/equilys-front-react"
                    target="_blank"
                  >
                    <Button variant="secondary">
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}

          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name} id="Kalmia">
              <iframe
                className={styleClasse.section.iframe.name}
                src="https://kalmia.yvernaux.ynh.fr/register"
                title="site Equilys"
                height={500}
                onLoad={() => setLoading(false)}
              ></iframe>

              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>Projet Kalmia</Card.Title>
                  <Card.Text className="text-start">
                    {t("projects.kalmia.text.part1")}{" "}
                    <a
                      rel="noreferrer"
                      href="https://www.vanaprincipia.fr"
                      target="_blank"
                      aria-label="link to website Vana Principia"
                    >
                      Vana Principia
                    </a>
                    .<br />
                    {t("projects.kalmia.text.part2")}
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    Partenaires :{" "}
                    <img
                      src={logoVanaPrincipia}
                      className="img-fluid me-2"
                      alt="logo de Vana Principia"
                      width={25}
                    />
                    <img
                      src={logoLaravel}
                      className="img-fluid me-2"
                      alt="logo de Laravel"
                      width={25}
                    />
                    <Icon.Gitlab size={25} color="orange" />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Auteurs : Alan Bart - Kester Kaça - Mehdi Khalil - Lucas
                    Yvernaux
                  </ListGroup.Item>
                  <ListGroup.Item>2023</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://kalmia.yvernaux.ynh.fr/"
                    aria-label="Link to website"
                    target="_blank"
                  >
                    <Button variant="primary">
                      {t("projects.CTAWebsite")}
                    </Button>
                  </Card.Link>
                  <Card.Link
                    href="https://gitlab.com/lucas.yvernaux/projet-kalmia-dev"
                    target="_blank"
                  >
                    <Button variant="secondary">
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}

          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name} id="Catalogue">
              <iframe
                className={styleClasse.section.iframe.name}
                src="https://catalogue.yvernaux.ynh.fr/register"
                title="Site web catalogue multimedia"
                height={500}
                onLoad={() => setLoading(false)}
              ></iframe>

              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>Catalogue Multimedia</Card.Title>
                  <Card.Text>{t("projects.catalogue.text")}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    Technos :{" "}
                    <img
                      src={logoLaravel}
                      className="img-fluid me-2"
                      alt="logo de Laravel"
                      width={25}
                    />
                    <Icon.Gitlab color="orange" size={25} />
                  </ListGroup.Item>
                  <ListGroup.Item>Author : Lucas Yvernaux</ListGroup.Item>
                  <ListGroup.Item>2023</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://catalogue.yvernaux.ynh.fr/register"
                    aria-label="Link to website"
                    target="_blank"
                  >
                    <Button variant="primary">
                      {t("projects.CTAWebsite")}
                    </Button>
                  </Card.Link>
                  <Card.Link
                    onClick={handleClickDisabled}
                    href="https://gitlab.com/lucas.yvernaux/catalogue"
                    target="_blank"
                  >
                    <Button
                      variant="secondary"
                      disabled
                      style={{
                        cursor: "not-allowed",
                        pointerEvents: "inherit",
                      }}
                    >
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}

          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name} id="Lak">
              <iframe
                className={styleClasse.section.iframe.name}
                src="https://lac.yvernaux.ynh.fr/register"
                title="site Equilys"
                height={500}
                onLoad={() => setLoading(false)}
              ></iframe>

              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>Projet LAK</Card.Title>
                  <Card.Text>{t("projects.lak.text")}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    Technos :{" "}
                    <img
                      src={logoLaravel}
                      className="img-fluid me-2"
                      alt="logo de Laravel"
                      width={25}
                    />
                    <Icon.Gitlab size={25} color="orange" />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Author : Lucas Yvernaux - Alan Bart - Kester Kaça
                  </ListGroup.Item>
                  <ListGroup.Item>2024</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://lac.yvernaux.ynh.fr/"
                    aria-label="Link to website"
                    target="_blank"
                  >
                    <Button variant="primary">
                      {t("projects.CTAWebsite")}
                    </Button>
                  </Card.Link>
                  <Card.Link
                    href="https://gitlab.com/lucas.yvernaux/projet-lac"
                    target="_blank"
                  >
                    <Button variant="secondary">
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}
          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name}>
              <iframe
                className={styleClasse.section.iframe.name}
                src="https://manga-collec.yvernaux.ynh.fr/register"
                title="Application manga Collec"
                height={500}
                onLoad={() => setLoading(false)}
              ></iframe>
              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>Manga Collec</Card.Title>
                  <Card.Text>{t("projects.mangaCollec.text")}</Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    Technos :{" "}
                    <img
                      src={logoLaravel}
                      className="img-fluid me-2"
                      alt="logo de Laravel"
                      width={25}
                    />
                    <Icon.Gitlab color="orange" size={25} />
                  </ListGroup.Item>
                  <ListGroup.Item>Author : Lucas Yvernaux</ListGroup.Item>
                  <ListGroup.Item>2023</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://manga-collec.yvernaux.ynh.fr/"
                    aria-label="Link to website"
                    target="_blank"
                  >
                    <Button variant="primary">
                      {" "}
                      {t("projects.CTAWebsite")}
                    </Button>
                  </Card.Link>
                  <Card.Link
                    href="https://gitlab.com/lucas.yvernaux/manga-collec"
                    target="_blank"
                  >
                    <Button variant="secondary">
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}
          {loading ? (
            <CardPlaceholder />
          ) : (
            <Card className={styleClasse.section.card.name}>
              <iframe
                className={styleClasse.section.iframe.name}
                height="500"
                src="https://embed.figma.com/proto/wP3HDQT84jQ8k1wHqxB6Qi/SAME?node-id=145-534&node-type=canvas&scaling=scale-down&content-scaling=fixed&page-id=1%3A3&starting-point-node-id=145%3A518&embed-host=share"
                //src="http://localhost:8000/"
                title="iframe maquette projet same"
                allowfullscreen
              ></iframe>
              <div className={styleClasse.section.content.name}>
                <Card.Body>
                  <Card.Title>Projet Same</Card.Title>
                  <Card.Text>
                    Ce projet a été réaliser lors d'un Hackathon lors de mon
                    master à l'ECV Digital
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush font-monospace">
                  <ListGroup.Item>
                    Technos :{" "}
                    <img
                      src={logoLaravel}
                      className="img-fluid me-2"
                      alt="logo de Laravel"
                      width={25}
                    />
                    <Icon.Gitlab color="orange" size={25} />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Author : Luca Oltra - Nicolas Da Costa - Eva Pierrot - Alan
                    Bart - Lucas Yvernaux
                  </ListGroup.Item>
                  <ListGroup.Item>2022</ListGroup.Item>
                </ListGroup>
                <Card.Body>
                  <Card.Link
                    href="https://www.figma.com/proto/wP3HDQT84jQ8k1wHqxB6Qi/SAME?node-id=145-534&node-type=canvas&t=ubiQzR5ux4aXapjp-1&scaling=scale-down&content-scaling=fixed&page-id=1%3A3&starting-point-node-id=145%3A518"
                    aria-label="Link to Figma"
                    target="_blank"
                  >
                    <Button variant="primary">Visiter la maquette</Button>
                  </Card.Link>
                  <Card.Link
                    href="https://gitlab.com/lucas.yvernaux/projet-same"
                    target="_blank"
                  >
                    <Button variant="secondary">
                      <Icon.Gitlab />
                      <span className="ms-1">Repository</span>
                    </Button>
                  </Card.Link>
                </Card.Body>
              </div>
            </Card>
          )}
        </section>
      </section>
    </>
  );
}
