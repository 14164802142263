import { useId } from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export function Input({
  InputError,
  type = "text",
  label,
  smallInput,
  ...props
}) {
  const id = useId();

  return (
    <>
      <FloatingLabel
        controlId={id}
        label={label}
        className={smallInput ? "w-50" : ""}
      >
        {type == "textarea" ? (
          <>
            <Form.Control
              isInvalid={InputError}
              type={type}
              as={type}
              placeholder={label}
              style={{ height: "200px" }}
              {...props}
            />
            <Form.Control.Feedback type="invalid">
              {InputError}
            </Form.Control.Feedback>
          </>
        ) : (
          <>
            <Form.Control
              type={type}
              {...props}
              placeholder="pedro"
              isInvalid={InputError}
            />
            {InputError && (
              <Form.Control.Feedback type="invalid" className="text-start">
                {InputError}
              </Form.Control.Feedback>
            )}
          </>
        )}
      </FloatingLabel>
    </>
  );
}
