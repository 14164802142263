import { SocialIcon } from "react-social-icons";

export const SocialMedia = ({ about, bgColor }) => {
  return (
    <>
      <SocialIcon
        className="mb-3"
        bgColor={bgColor}
        url="https://www.linkedin.com/in/lucas-yvernaux/"
        target="_blank"
      />
      <SocialIcon
        className="mb-3"
        bgColor={bgColor}
        url="https://github.com/lucasYvernaux?tab=repositories"
        target="_blank"
      />
      <SocialIcon
        className="mb-3"
        bgColor={bgColor}
        url="https://gitlab.com/users/lucas.yvernaux/projects"
        target="_blank"
      />

      {about && (
        <>
          <SocialIcon
            className="mb-3"
            bgColor={bgColor}
            url="https://www.instagram.com/lucas78ljr_/"
            target="_blank"
          />

          <SocialIcon bgColor={bgColor} url="/Contact" network="email" />
        </>
      )}
    </>
  );
};
