import { useEffect, useState } from "react";
import { Card, ListGroup, Placeholder } from "react-bootstrap";

export default function CardPlaceholder() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <>
      <Card
        className={
          isSmallScreen ? "shadow-lg flex-column" : "shadow-lg flex-row"
        }
      >
        <div className={isSmallScreen ? "m-auto mt-4" : "w-50 m-auto"}>
          <svg
            className="placeholder-glow"
            height={isSmallScreen ? 200 : 300}
            width={isSmallScreen ? 200 : 300}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className="placeholder m-auto"
              width={isSmallScreen ? 200 : 300}
              height={isSmallScreen ? 200 : 300}
              rx="15"
              ry="15"
            />
          </svg>
        </div>

        <div className={!isSmallScreen && "w-50"}>
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
              <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
              <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
              <Placeholder xs={8} />
            </Placeholder>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroup.Item>
              <Placeholder xs={4} />{" "}
            </ListGroup.Item>
            <ListGroup.Item>
              <Placeholder xs={4} />{" "}
            </ListGroup.Item>
            <ListGroup.Item>
              <Placeholder xs={4} />{" "}
            </ListGroup.Item>
          </ListGroup>
          <Card.Body>
            <Placeholder.Button variant="primary" xs={3} />
            <Placeholder.Button variant="secondary" xs={3} className="ms-3" />
          </Card.Body>
        </div>
      </Card>
    </>
  );
}
