import photo from "../assets/profile-pic.png";
import { Button } from "../components";
import fileCV from "../assets/file/CV-LucasYvernaux-2024.pdf";
import parcours from "../assets/file/Parcours.jpg";

import { useEffect, useState } from "react";
import { SocialMedia } from "../components/SocialMedia";
import { useTranslation } from "react-i18next";

export function About() {
  const { t } = useTranslation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const style = {
    section: {
      minHeight: "100vh",
      width: "100vw",
    },
  };

  return (
    <>
      <section
        id="sectionAbout"
        style={style.section}
        className={
          isSmallScreen
            ? "d-flex w-100 flex-column pt-5"
            : "d-flex w-100 flex-row p-5"
        }
      >
        <div
          className={
            isSmallScreen
              ? "d-none"
              : " d-flex flex-column justify-content-center"
          }
        >
          <SocialMedia about />
        </div>
        <div className="d-flex flex-column mx-5">
          <h1
            className="display-1 mb-3"
            style={{ fontFamily: "Montserrat Classic" }}
          >
            {t("about.title")}
          </h1>

          <div>
            <div className="d-flex justify-content-center">
              <img
                src={photo}
                alt="Portrait de Lucas Yvernaux"
                width={200}
                height={200}
              />
            </div>
            <p className="mt-3 fs-3 text-start bg-primary-subtle rounded p-3 shadow-lg">
              {t("about.text")}
            </p>
          </div>
          <div
            className={
              isSmallScreen
                ? "d-flex flex-column gap-3 mb-3"
                : "d-flex gap-5 justify-content-center"
            }
          >
            <Button
              href={fileCV}
              download="CV-LucasYvernaux-2024.pdf"
              alt="CTA to download my CV"
            >
              {t("about.CTA.CV")}
            </Button>
            <Button variant="secondary" href="#Parcours">
              {t("about.CTA.Parcours")}
            </Button>
          </div>
        </div>
        <div
          className={
            isSmallScreen
              ? " d-flex flex-row justify-content-center gap-2"
              : " d-none"
          }
        >
          <SocialMedia about />
        </div>
      </section>

      <section
        id="sectionLanding"
        style={style.section}
        className={
          isSmallScreen
            ? "d-flex w-100 flex-row pt-3 text-light"
            : "d-flex w-100 flex-row p-5 text-light"
        }
      >
        <div
          id="Parcours"
          className={
            isSmallScreen
              ? "d-flex flex-column w-100"
              : "d-flex flex-column w-100"
          }
        >
          <img
            src={parcours}
            width="70%"
            className="m-auto"
            alt="diagram showing my career path"
          />
        </div>
      </section>

      <section
        id="sectionAbout"
        style={style.section}
        className={
          isSmallScreen
            ? "d-none w-100 flex-row pt-3"
            : "d-none w-100 flex-row p-5"
        }
      >
        <div
          className={
            (isSmallScreen && "d-none") +
            " d-flex flex-column justify-content-center"
          }
        >
          <SocialMedia />
        </div>
        <div className="d-flex flex-column mx-5">
          <h1 className="display-1 mb-3"> A propos de ma Scolarité</h1>

          <div>
            <div className="d-flex justify-content-center">
              <img
                src={photo}
                alt="Portrait de Lucas Yvernaux"
                width={200}
                height={200}
              />
            </div>
            <p className="mt-3 fs-3 text-start">
              Transformez vos idées en sites web exceptionnels grâce à mes
              compétences en développement front-end. Contactez-moi aujourd'hui
              pour une collaboration qui fera briller votre présence sur le web.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
