import { useEffect, useState } from "react";

//import photo
import photo from "../assets/profile-pic.png";

//import Components
import { SocialMedia } from "../components/SocialMedia";
import { Contact } from "./Contact";
import { CarouselCustom } from "../components/CarouselCustom";
import { Button } from "../components";
import { useTranslation } from "react-i18next";

export function Home() {
  const { t } = useTranslation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    mediaQuery.addEventListener("change", handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const style = {
    section: {
      minHeight: isSmallScreen ? "" : "100vh",
      paddingTop: isSmallScreen ? "3rem" : "",
      display: "flex",
      alignItems: "center",
      width: "100vw",
      flexDirection: isSmallScreen ? "column-reverse" : "row",
      socialMedia: {
        display: isSmallScreen ? "none" : "flex",
        flexDirection: "column",
      },
      content: {
        flex: 2,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: isSmallScreen ? "1rem" : "0 3rem",
        title: {
          fontFamily: "Roboto",
          flex: 1,
          display: "flex",
          justtifyItems: "end",
          fontSize: "60px",
        },
        text: {
          fontSize: "30px",
          textAlign: "start",
          flex: 1,
        },
      },
      photo: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
      },
      button: {
        marginTop: "1rem",
      },
    },
  };
  return (
    <>
      <section id="sectionLanding" style={style.section}>
        <div style={style.section.socialMedia} className="ms-3">
          <SocialMedia />
        </div>
        <div style={style.section.content} className="text-light">
          <h1 style={style.section.content.title}>{t("home.landing.title")}</h1>
          <p style={style.section.content.text}>{t("home.landing.text")}</p>
        </div>
        <div style={style.section.photo}>
          <img src={photo} alt="Portrait de Lucas Yvernaux" width="75%" />
        </div>
      </section>

      <section id="sectionLandingProject" style={style.section}>
        <div style={style.section.content}>
          <h1 style={style.section.content.title} className="my-5">
            {t("home.landing.project.title")}
          </h1>
          <CarouselCustom className={isSmallScreen ? "mx-0" : "mx-5"} />
          <div className="my-5">
            <Button variant="secondary" href="/#Projects">
              {t("home.CTAReadMore")}
            </Button>
          </div>
        </div>
      </section>
      <Contact />
    </>
  );
}
